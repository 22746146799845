import axios from "axios";

export const baseURL = process.env.VUE_APP_API_URL;
export const contentBaseURL = process.env.VUE_APP_CMS_API_URL;

console.log("API URL: ", process.env.VUE_APP_API_URL);
console.log("Content URL: ", process.env.VUE_APP_CMS_API_URL);
export const HTTP = axios.create({
  auth: {
    username: "c2g",
    password: "=D/f/neK(dX!5f2K7buN",
  },
  baseURL: baseURL,
  contentBaseURL: contentBaseURL,
});

export const HTTPContent = axios.create({
  baseURL: contentBaseURL,
});
