<template>
    <section>
        <!-- Retrieve policy / quote input modal -->
        <b-modal :active.sync="siteData.retrieveModalActive" has-modal-card trap-focus aria-role="dialog" aria-modal scroll="keep">
            <form @submit.prevent="retrieve">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title has-text-centered has-text-weight-bold">Retrieve Quote / Renewal</p>
                    </header>
                    <section class="modal-card-body">
                        <p class="error">{{ error }}</p>
                        <!--<p class="error">The policy number you have entered has not been recognised. Please try again.</p>-->
                        <b-field label="Enter your policy number here:">
                            <b-input type="text" v-model="policyNumber" placeholder="eg. C2G6283123123" required/>
                        </b-field>
                        <b-field label="Email:">
                            <b-input type="email" v-model="email" placeholder="enquiries@covered2go.co.uk" required/>
                        </b-field>
                    </section>
                    <footer class="modal-card-foot level">
                        <button class="button level-item" type="button" @click="siteData.retrieveModalActive = false">Close</button>
                        <button class="button is-primary level-item">Retrieve</button>
                    </footer>
                </div>
            </form>
        </b-modal>

        <!-- Message modal -->
        <b-modal v-model="errorModal" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <p>Dear Customer,</p>
                    <br>
                    <p>We hope you are keeping safe and well.</p>
                    <br>
                    <p>During these unprecedented times it has been essential for us to ensure our products continue to be fit for purpose.</p>
                    <br>
                    <p>Due to improving our products, which now include enhanced, Covid-19 Cover, we are unable to honour the renewal quote previously provided, as we can no longer provide your cover on a ‘like-for-like’ basis. </p>
                    <br>
                    <!--<p>However, we would be absolutely delighted for you to obtain a <a href="https://www.covered2go.co.uk/get-quote">NEW QUOTE</a> and would like to offer you the same <strong>10% DISCOUNT</strong> for choosing to stay with us by using the code <strong>THANKYOU10</strong> at the checkout. To view our products, and obtain a new quotation, please <a href="https://www.covered2go.co.uk/get-quote">click here</a>.</p>
                    <br>-->
                    <p>If we can be of any further assistance, please do not hesitate to contact us.</p>
                    <br>
                    <p>Best wishes,</p>
                    <p>Your Covered2go Team.</p>
                </div>
            </div>
        </b-modal>

        <!-- Message modal -->
        <b-modal v-model="expiredModal" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <p>Quote was issued over 14 days ago and is therefore expired.</p>
                    <p>Please create another quotation on our website by clicking <a href="/get-quote">here</a></p>
                </div>
            </div>
        </b-modal>

        <!-- Message modal -->
        <b-modal v-model="passedModal" :width="640" scroll="keep">
            <div class="card">
                <div class="card-content">
                    <p>The start date of this quotation has now passed.</p>
                    <p>To update this information please create a new quotation on our website by clicking <a href="/get-quote">here</a></p>
                </div>
            </div>
        </b-modal>

    </section>
</template>

<script>
    import { policyAPI } from "../../mixins/policy-api";
    import { mapActions } from "vuex";

    export default {
        name: "RetrieveQuoteModal",
        props: ['siteData'],
        data() {
            return {
                policyNumber: '',
                email: '',
                error: '',
                errorModal: false,
                expiredModal: false,
                passedModal: false
            }
        },
        methods: {
            ...mapActions({
                setPolicy: 'setPolicy',
                setReqPolicy: 'setReqPolicy',
                setUpsellPolicy: 'setUpsellPolicy'
            }),
            // Retrieve quote or renewal by entered policy number
            retrieve() {
                this.error = '';
                let number = this.policyNumber.replace('C2G6283', '');
                let email = this.email;

                number = number.replace('c2g6283', '');
                if (!isNaN(number) && number !== '') {
                    this.retrievePolicyWithEmail(number,btoa(email)).then((res) => {
                        if(res !== undefined) {

                            if (res.data["error 142"]) {
                                this.error = res.data["error 142"].customer;
                            } else {
                                if(res.data.policy.covid19 === 'N' && res.data.policy.policy_product !== 'silver') {
                                    this.errorModal = true;
                                    throw Error();
                                }

                                // Set global policy object
                                this.setPolicy(res.data);
                                this.setReqPolicy(res.data);

                                // Choose where to redirect to
                                if(res.data.policy.status === 'quote') {

                                    if(this.$moment(this.$moment(new Date().toISOString().split('T')[0])).isAfter(this.$moment(res.data.policy.issue_date).add(14, 'days'))) {
                                        this.expiredModal = true;
                                        throw Error();
                                    }

                                    if (this.$moment(this.$moment(new Date().toISOString().split("T")[0])).isAfter(this.$moment(res.data.policy.start_date))) {
                                        this.passedModal = true;
                                        throw Error();
                                    }

                                    this.$router.push({name: 'policyDetails'});
                                } else if(res.data.policy.status === 'renewal' || res.data.policy.status === 'policy') {

                                    if (this.$moment(this.$moment(new Date().toISOString().split("T")[0])).isAfter(this.$moment(res.data.policy.start_date))) {
                                        this.expiredModal = true;
                                        throw Error();
                                    }

                                    this.$router.push({name: 'purchasePolicy'});
                                }
                                this.siteData.retrieveModalActive = false;
                            }

                        }
                    }).catch(() => {
                        this.error = 'Quote or Renewal not found';
                    });
                } else {
                    this.error = "Please enter a valid policy number";
                }

            }
        },
        mixins: [policyAPI]
    }
</script>

<style scoped>
    .error {
        color: red;
    }
</style>